export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: '62198175306-h477rqb0lmglnlolib9pn2m9pjudrm2h.apps.googleusercontent.com',
    IP_FIND_KEY: '89faecdb-c41e-4ba0-8eb3-6dd1a731eddc',
    ROOT_URL: 'https://blackwidow.fyle.tech',
    APP_URL: 'https://blackwidow1.fyle.tech/app',
    APP_ROUTER_URL: 'https://blackwidow-accounts.fyle.tech',
    ROUTER_URL: 'https://blackwidow-accounts.fyle.tech',
    SENTRY_DSN: 'https://971bdf3cefe24ef88d6e1cbb65b5d535@sentry.io/3554166',
    SEND_ERROR_TO_SENTRY: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.2,
    ENVIRONMENT: 'blackwidow',
    RELEASE: '1cc5e6934f3ccdc818e47b96e6cb2844dc586292',
    TARGET: 'fyle',
    GOOGLE_MAPS_API_KEY: 'AIzaSyAN1rmM-KdfNimjKxUwRkQB2ASpY6Czrvc',
    DWOLLA_ENVIRONMENT: 'sandbox',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe54',
    NETLIFY_FYLE_APP_KEY: 'undefined',
    SLACK_FEEDBACK_API_URL: 'undefined',
    MIXPANEL_PROJECT_TOKEN: 'dce787f3a74f5ede8264af230a4b4652',
    MIXPANEL_PROXY_URL: 'https://blackwidow.fyle.tech/mixpanel',
    USE_MIXPANEL_PROXY: 'false',
    ENABLE_MIXPANEL: 'false',
  };